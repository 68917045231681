import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import { graphql } from "gatsby"
import { Box, Grid, Image} from "theme-ui"
// import Checkout from "../components/checkout"
import "../styles/book-post.css"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types"
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { Button } from "@theme-ui/components"
import { node } from "prop-types"
// This is other-book-post.js: posting other book post


function OtherBlogPost({ data }) {
  const book = data.allContentfulSuzzie.edges[0].node

  const Bold = ({ children }) => <span className="bold">{children}</span>
  const Text = ({ children }) => <p className="align-center">{children}</p>
  
  const options = {
  renderMark: {
    [MARKS.BOLD]: text => <Bold>{text}</Bold>,
    },
    renderNode: {
        [MARKS.CODE]: (node) => {
        return(
        <Button>{node}</Button>
        )}
        },
    renderNode:  {
      [BLOCKS.PARAGRAPH]: (node, children) => {
        return(
          <Box className="intro-text"> 
          <Text>{children}</Text>
          </Box>
        )
        },
      },
    }

  return (
    <Layout>
        <h1 dangerouslySetInnerHTML={{__html: book.title}} />
    <Grid  gap={2} columns={[2, '1fr 2fr']}  className="books-display">
    <Box style={{textAlign:"center"}}>
        <Image className="books-img" key={book.thumbnail.fixed.src} src={book.thumbnail.fixed.src} />
        <br />
        {/* <Checkout /> */}
      </Box>
      <Box>
      {documentToReactComponents(JSON.parse(book.content.raw),options)}
      <a class="custom-dbox-popup" href={"https://donorbox.org/" + book.slug}>
        <img src="https://donorbox.org/images/png-donate/button-medium-blue.png" />
        </a>
        </Box>        
      </Grid>
    </Layout>
  )
}

export default OtherBlogPost

export const query = graphql`
  query($slug: String!) {
    allContentfulSuzzie(filter: {slug: {eq : $slug} }) {
      edges {
        node {
          title
          slug
          content {
            raw
          }
          thumbnail {
            contentful_id
            fixed(width: 250)   {
              src
            }
          }
        }
      }
    }
  }
`